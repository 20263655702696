.App {
  height: 100vh;
}

.App-logo {
  height: 30px;
}

.App-link {
  color: #09d3ac;
}

a {
  text-decoration: none;
}

header {
  overflow: visible;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  font-size: 15px;
  line-height: 1.5;
  color: white;
  background-color: #282c34;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  justify-content: space-between; 
}

.header-link {
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  margin-left: 1rem;
  background-color: #282c34;
}

.header-link:hover {
  color: #bbb;
}


.ad-div {
  font-size: 15px;
  position: fixed;
  bottom: 0;
  background-color: #282c34;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
  z-index: 10;
  padding: 7px 10px;
}

.ad-div a {
  color: #09d3ac;
}

