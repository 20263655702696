.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  padding-top: 8px;
  right: 0;
  background-color: #ffffff00;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10;
  border-radius: 5px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  background-color: #f9f9f9;
  padding: 5px 16px;
  display: block;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #0366d6;
  color: white;
}

.dropdown-content hr {
  margin: 0;
  height: 0;
  overflow: hidden;
  background-color: #f9f9f9;
  border: 0;
  border-bottom: 1px solid #dfe2e5;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
